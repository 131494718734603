<template>
	<div class="w-50">
		<div
			v-if="hasData"
			class="d-flex align-items-stretch"
			@mouseenter="setItemActive">
			<div
				v-if="tree.allow_position_movement && tree.binary_placement == 'right'"
				class="p-0 d-flex align-items-stretch"
				:class="secondLevel ? 'col-3' : 'col-2'">
				<button
					class="btn btn-primary rounded-0 w-100 h-100 d-flex align-items-center justify-content-center tree-movement"
					@mouseenter="$emit('highlightNode', tree.distributor_id)"
					@mouseleave="$emit('clearHighlight')"
					@click="moveNode(tree.distributor_id, tree.username, tree.binary_placement, 'left', tree.parent_id, parentInfo)">
					<span
						class="fas fa-arrow-alt-circle-left"
						style="font-size: 16px;" />
					<span
						class="text-small pl-1"
						style="line-height: 1;">{{ translate('move_left') }}</span>
				</button>
			</div>
			<div
				:class="{'col-10': tree.allow_position_movement && !secondLevel, 'col-9': secondLevel, 'col-12': !tree.allow_position_movement, 'bg-secondary': activeClass}"
				:style="!['xs','sm'].includes(windowWidth) ? 'height: 55px;' : ''"
				class="border tree-item d-flex pointer"
				@click="changeUser(tree.distributor_id)">
				<strong
					class="d-block text-gray-dark m-auto text-truncate">
					<i
						v-if="!(tree.is_spillover || tree.is_unauthorized)"
						:class="findColor(tree.status.toLowerCase())"
						class="mr-1" />
					<template v-if="!!tree.username">
						{{ tree.username }}
					</template>
					<template v-if="tree.is_spillover">
						{{ translate('spillover').toUpperCase() }}
					</template>
					<template v-if="tree.is_unauthorized">
						{{ translate('unauthorized').toUpperCase() }}
					</template>
					<i
						v-if="tree.autoship_failed"
						:class="`${ autoshipFailed.icon } ${ autoshipFailed.color }`"
						class="mr-1" />
					<span
						v-if="tree.has_ticket && tree.has_enabled_ticket"
						v-b-tooltip.hover
						:title="translate('has_purchased_ticket')"
						style="vertical-align: text-top;margin-left:5px;">
						<i class="fa fa-ticket primary-color" />
					</span>
					<span
						v-if="tree.has_ticket && !tree.has_enabled_ticket"
						v-b-tooltip.hover
						:title="translate('has_pending_ticket')"
						style="vertical-align: text-top;margin-left:5px;">
						<i class="fa fa-ticket text-muted" />
					</span>
					<br>
					<span class="font-weight-light">({{ tree.is_spillover || tree.is_unauthorized ? translate('NA') : tree.distributor_id }})</span>
				</strong>
			</div>
			<div
				v-if="tree.allow_position_movement && tree.binary_placement == 'left'"
				class="p-0 d-flex align-items-stretch"
				:class="secondLevel ? 'col-3' : 'col-2'">
				<button
					class="btn btn-primary rounded-0 w-100 h-100 d-flex align-items-center justify-content-center tree-movement"
					@mouseenter="$emit('highlightNode', tree.distributor_id)"
					@mouseleave="$emit('clearHighlight')"
					@click="moveNode(tree.distributor_id, tree.username, tree.binary_placement, 'right', tree.parent_id, parentInfo)">
					<span
						class="text-small pr-1"
						style="line-height: 1;">{{ translate('move_right') }}</span>
					<span
						class="fas fa-arrow-alt-circle-right"
						style="font-size: 16px;" />
				</button>
			</div>
		</div>
		<div
			v-else
			:class="{
				'bg-secondary': activeClass,
				'highlighted-node': highlighted,
			}"
			:style="{
				height: !['xs', 'sm'].includes(windowWidth) ? '55px' : '',
			}"
			class="border tree-item d-flex align-items-center justify-content-center">
			<template v-if="openPosition !== '' && $user.details().type === distributor">
				<span
					v-if="highlighted"
					class="text-center text-uppercase align-middle">
					{{ translate('move_here') }}
				</span>
				<b-button
					v-else-if="isCurrentUserActive"
					v-b-tooltip.hover
					:title="['xs', 'sm'].includes(windowWidth) ? openPosition : ''"
					variant="primary"
					class="m-auto"
					@click="$emit('registerInPosition', { parent: $parent.tree.distributor_id, placement, spillover: $parent.tree.is_spillover })">
					<span v-if="['xs', 'sm'].includes(windowWidth)">
						<i class="fas fa-plus" />
					</span>
					<span v-else>
						{{ openPosition }}
					</span>
				</b-button>
				<b-button
					v-else
					v-b-tooltip.hover.html="translate('your_account_is_inactive_link', { points: pointsToActivate, link: $router.resolve({ name: 'MainStore' }).href })"
					variant="secondary"
					class="m-auto">
					<span v-if="['xs', 'sm'].includes(windowWidth)">
						<i class="fas fa-plus" />
					</span>
					<span v-else>
						{{ openPosition }}
					</span>
				</b-button>
			</template>
		</div>
		<div class="tree-level">
			<child-binary
				v-if="deep > 1"
				:active-user="activeUser"
				:tree="childItem('l')"
				:deep="deep-1"
				:parent-info="parentName(tree)"
				placement="left"
				:highlighted="highlightedNode === brotherItemId('r')"
				:second-level="true"
				@highlightNode="highlightedNode = $event"
				@clearHighlight="highlightedNode = null"
				@registerInPosition="registerInPosition" />
			<child-binary
				v-if="deep > 1"
				:active-user="activeUser"
				:tree="childItem('r')"
				:deep="deep-1"
				:parent-info="parentName(tree)"
				placement="right"
				:highlighted="highlightedNode === brotherItemId('l')"
				:second-level="true"
				@highlightNode="highlightedNode = $event"
				@clearHighlight="highlightedNode = null"
				@registerInPosition="registerInPosition" />
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';
import { ROWS } from '@/settings/Tree';
import { distributor } from '@/settings/Roles';
import { AUTOSHIP_FAILED as autoshipFailed, STATUSES } from '@/settings/Statuses';
import { Network, Users, Events } from '@/translations';
import TreeMixin from '../mixins/Tree';
import { POINTS_TO_ACTIVATE } from '@/settings/Points';

export default {
	name: 'BinaryTreeItem',
	messages: [Network, Users, Events],
	mixins: [WindowSizes, TreeMixin],
	props: {
		activeUser: {
			type: Number,
			default: null,
		},
		tree: {
			type: [Object, Boolean],
			default() {
				return false;
			},
		},
		deep: {
			type: Number,
			default: null,
		},
		placement: {
			required: true,
			type: String,
			validator: (value) => ['left', 'right'].includes(value),
		},
		highlighted: {
			type: Boolean,
			default: false,
		},
		secondLevel: {
			type: Boolean,
			default: false,
		},
		parentInfo: {
			required: true,
			type: String,
		},
	},
	data() {
		return {
			autoshipFailed,
			distributor,
			pointsToActivate: POINTS_TO_ACTIVATE,
			highlightedNode: 0, // Tracks which node should be highlighted
		};
	},
	computed: {
		activeClass() {
			return this.activeUser === this.tree.distributor_id;
		},
		hasData() {
			return !!Object.keys(this.tree).length;
		},
		openPosition() {
			let rows = ROWS[this.windowWidth];
			if (typeof rows === 'undefined') {
				rows = ROWS.default;
			}

			if (this.deep === rows) {
				return this.translate('add_here');
			}

			if (typeof this.$parent.$props !== 'undefined' && this.$parent.$props.tree) {
				return this.translate('add_here');
			}
			return '';
		},
		isCurrentUserActive() {
			return this.$user.details().status === STATUSES.active;
		},
	},
	methods: {
		setItemActive(userid) {
			let id = this.tree.distributor_id;
			if (typeof userid === 'number') {
				id = userid;
			}
			this.$parent.setItemActive(id);
		},
		getDataFiltered(childRows) {
			const { params } = this.$route;
			this.$parent.getDataFiltered(childRows, params.userId);
		},
	},
};
</script>
<style>
	.tree-item{
		height: 100px;
	}
	.tree-level{
		display: flex;
		flex-wrap: wrap;
		margin-right: 0px;
		margin-right: 0px;
	}
	.tree-movement{
		background: #002d74;
		border-color: #002d74;
	}
	.tree-movement:hover{
		background: #001f57;
	}
	.highlighted-node{
		background: rgba(0, 31, 87, 0.9);
		color: white;
	}
</style>
